.product-profile {
  margin: 30px 0;

  .left {

    .image {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
      overflow: hidden;
      border-bottom: 1px solid #ccc;

      a {
        outline: none;

        img {
          width: 100%;
          -webkit-transition: all 1s ease; /* Safari and Chrome */
          -moz-transition: all 1s ease; /* Firefox */
          -ms-transition: all 1s ease; /* IE 9 */
          -o-transition: all 1s ease; /* Opera */
          transition: all 1s ease;
        }
      }
    }

    .thumbnail-images {
      margin-top: 15px;

      .thumbnail-image {
        padding-right: 5px;

        a {

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .right {
    background: #fff;

    .content {
      padding-top: 30px;

      .price {
        margin-top: 20px;

        p {
          font-size: 24px;
          color: $brand-green;
          font-weight: 600;
        }
      }

      .add-to-cart {

        button {
          margin-top: 0;
        }
      }
    }

    .tabs {
      margin-top: 50px;
    }
  }
}