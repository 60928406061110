// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Kalam:300,400,700&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Poiret+One:300,400,700&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Dosis);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Catamaran);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css?family=Asap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i&amp;subset=latin-ext);
// Fontawesome
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
// Variables
@import "variables";
// Porudcts
@import "products/category-list";
@import "products/product-list";
@import "products/subcategory-list";
@import "products/product-profile";
// Responsive
@import "responsive";

html {

  body {

    font-family: 'Montserrat', sans-serif;

    p {
      font-size: 16px;
      color: #555;
    }

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    a {
      color: $brand-brown;
      outline: none;
      transition: all .3s ease;

      &:hover {
        color: $brand-brown-hover;
        text-decoration: none;
      }
    }

    .brand-brown-button {
      color: #fff;
      font-size: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      background: $brand-brown;
      border: none;
      border-radius: 0;
      margin: 15px 0;
      outline: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

      &:hover, &:active, &:focus {
        background: $brand-brown-hover;
        color: #fff;
        border: none;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }

    .brand-green-button {
      color: #fff;
      font-size: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      background: $brand-green;
      border: none;
      border-radius: 0;
      margin: 15px 0;
      outline: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

      &:hover, &:active, &:focus {
        background: $brand-green-hover;
        color: #fff;
        border: none;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }

    .brand-blue-button {
      color: #fff;
      font-size: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      background: $brand-blue;
      border: none;
      border-radius: 0;
      margin: 15px 0;
      outline: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

      &:hover, &:active, &:focus {
        background: $brand-blue-hover;
        color: #fff;
        border: none;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }

    .material-shadow {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      }

      &.no-hover {

        &:hover {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
      }
    }

    // MAIN NAVIGATION
    #main-navigation {
      background: #fff;
      margin: 0;
      border: none;
      border-radius: 0;
      position: relative;
      z-index: 100;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
      min-height: 100px;

      .navbar-brand {
        padding: 10px;

        img {
          height: 80px;
        }
      }

      #search-form {
        margin-top: 25px;

        .input-group {
          width: 100%;

        }
      }

      a {
        color: #333;
        text-transform: uppercase;
        line-height: 70px;

        &:hover {
          color: $brand-green;
        }

        &.highlighted {
          color: $brand-red;
        }
      }

      li {

        &.open {

          & > a {
            background: transparent;

            &:hover {
              background: transparent;
            }
          }

          ul {

            li {

              a {
                text-transform: none;
                color: #333;
              }
            }
          }
        }
      }

      .dropdown {

        .dropdown-menu {

          & > li {

            & > a {

              line-height: 40px;
            }
          }
        }
      }
      // SUB-MENU for sub-categories
      .dropdown-submenu {
        position:relative;

        & > .dropdown-menu {
          top:0;
          left:100%;
          margin-top:-6px;
        }

        &:hover {
          & > .dropdown-menu {
            display:block;
          }
        }
      }
    }

    // MAIN CAROUSEL
    #main-carousel-wrapper {

      .col-xs-12 {
        padding: 0;

        #main-carousel {
          margin-bottom: 30px;
        }
      }
    }


    // MAIN CONTAINER
    .main-container {
      background: #fafafa;
    }


    #main-content-wrapper {
      background: url('/images/frontend/general/body-white-background.jpg') no-repeat;
      background-size: cover;

      // MAIN CONTENT
      #main-content {
        padding-top: 30px;
        padding-bottom: 30px;

        // PAGE HEADER
        .page-header {
          width: 100%;
          min-height: 600px;
          margin: 0 0 30px;
        }

        &.home {
          padding-top: 0;
          padding-bottom: 0;
          min-height: 800px;

          .popular-products-wrapper {
            width: 100%;
            padding: 20px 0 0;
            margin-bottom: 0;
            background: #5d0656;

            h2 {
              color: #fff;
              margin-bottom: 30px;
            }
          }
        }

        &.cart {
          min-height: 600px;

          .cart-wrapper {
            background: #fff;
            padding: 15px;

            table {

              tr {

                td {
                  vertical-align: middle !important;
                }
              }
            }

            .mobile-cart {
              tbody {
                td {
                  display: block;
                }
              }

              table {
                width: 100%;

                tr {
                  line-height: 2em;

                  th, td {
                    width: 50%;
                  }
                }
              }
            }
          }
        }

        &.order {
          min-height: 600px;

          .order-box {
            margin-bottom: 30px;

            .inner {
              padding: 15px;
              background: #fff;

              h3 {
                margin-top: 0;
              }
            }
          }
        }

        &.contact {
          min-height: 600px;

          .inner {
            background: #fff;
            padding: 15px;
          }
        }

        &.content-page {

          .inner {
            background: #fff;
            padding: 15px;
          }
        }
      }
    }



    // MAIN FOOTER
    #main-footer-wrapper {
      width: 100%;
      min-height: 50px;
      background: #333;

      #main-footer {
        line-height: 50px;
        color: #fff;

        ul {

          li {
            line-height: 200%;

            a {
              color: #fff;

              &:hover {
                color: $brand-green-hover;
              }
            }
          }
        }

        .arukereso-box {
            margin: 40px auto 0;

          & > div {
            width: 100%;
            line-height: 150%;

            a {
              color: #fff !important;
            }
          }
        }

        .copyright {

          p {
            margin: 0;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }

    // SCROLL UP BUTTON
    #scrollUp {
      bottom: 20px;
      right: 20px;
      padding: 20px;
      color: #fff;
      background: url('/images/frontend/general/scroll-up.png') no-repeat;
    }
  }
}

// NOTIFICATION
.site-notification {
  margin-bottom: 0;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}

// VOUCHER FORM
.voucher-form {
  .voucher-code {
    display: inline-block;
    width: 50%;
  }
}