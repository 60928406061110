.products-wrapper {
  margin: 30px 0;

  .product-box {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    margin-bottom: 50px;
    background: #fff;

    .image {
      overflow: hidden;
      border-bottom: 1px solid #ccc;

      a {

        img {
          width: 100%;
          -webkit-transition: all 1s ease; /* Safari and Chrome */
          -moz-transition: all 1s ease; /* Firefox */
          -ms-transition: all 1s ease; /* IE 9 */
          -o-transition: all 1s ease; /* Opera */
          transition: all 1s ease;
        }

        &:hover {

          img {
            -webkit-transform: scale(1.25); /* Safari and Chrome */
            -moz-transform: scale(1.25); /* Firefox */
            -ms-transform: scale(1.25); /* IE 9 */
            -o-transform: scale(1.25); /* Opera */
            transform: scale(1.25);
          }
        }
      }
    }

    .content {
      height: 85px;
      padding: 0 15px 0;

      h3 {
        font-size: 14px !important;

        &.title {

          a {
            font-size: 14px;
          }
        }
      }

      .price {
        padding-top: 15px;

        p {
          font-size: 14px;
          font-weight: 600;
          color: $brand-green;
        }
      }
    }
  }
}