/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/




/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}



/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  body {

    #main-navigation {
        min-height: 0 !important;

      .navbar-header {

        .navbar-brand {

          img {
            height: 100% !important;
          }
        }
      }

      .navbar-nav {

        li {

          a {
            line-height: 30px !important;
          }
        }
      }
    }
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .dropdown-submenu {
    position:relative;

    & > .dropdown-menu {
      top:0;
      left:100%;
      margin-top:-6px;
      display: block;

      .menu-item {
        padding-left: 10px;
      }
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}